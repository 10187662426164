import AuthenticationService from "@/services/AuthenticationService";
import store from "@/store";
import alerts from "@/components/alerts/alert";
import router from "@/router";

const sidebar = {
  namespaced: true,
  state: {
    sidebar: {},
  },
  getters: {
    getSidebar(state) {
      return state.sidebar;
    },
  },
  mutations: {
    SET_SIDEBAR(state, sidebar) {
      state.sidebar = sidebar;
    },
  },
  actions: {
    reqSidebar({ commit }) {
      return AuthenticationService.get("access-menus/user/menu")
        .then(({ data }) => {
          commit("SET_SIDEBAR", data.data);
        })
        .catch((error) => {
          alerts.sweetalertToast(
            "error",
            error.response.data.message || error.response.data.error
          );
          commit("SET_LOADING", false);
          store.dispatch("auth/LogOut");
          router.push({ name: "Login" });
        });
    },
    setIsAactiveFalse({ commit, state }) {
      const menuNew = state.sidebar;
      menuNew.forEach((element, i) => {
        if (element.slug === "dropdown") {
          menuNew[i].isActive = false;
        }
      });
      commit("SET_SIDEBAR", menuNew);
    },
  },
};

export default sidebar;
