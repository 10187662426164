import Api from "@/services/Api";
import ApiFormData from "./ApiFormData";
export default {
  get(endpoin) {
    return Api().get(endpoin);
  },
  post(endpoin, payload) {
    return Api().post(endpoin, payload);
  },
  postFormData(endpoin, payload) {
    return ApiFormData().post(endpoin, payload);
  },
  put(endpoin, payload) {
    return Api().put(endpoin, payload);
  },
  patch(endpoin, payload) {
    return Api().patch(endpoin, payload);
  },
  delete(endpoin, payload) {
    return Api().delete(endpoin, payload);
  },
};
