import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VCard } from 'vuetify/lib/components/VCard';
import { VImg } from 'vuetify/lib/components/VImg';
import { VList } from 'vuetify/lib/components/VList';
import { VListGroup } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VCard,{staticClass:"mx-auto",attrs:{"width":"100%","tile":"","color":"transparent"}},[_c(VList,{attrs:{"dark":"","dense":"","rounded":"","color":"transparent"}},[_vm._l((_vm.sidebar),function(menu,i){return [(menu.menu_type === 'LINK')?_c(VListItem,{key:'item' + menu.menu_id,staticClass:"mt-2 mb-2",attrs:{"to":menu.route},on:{"click":function($event){return _vm.toggleSubmenu()}},model:{value:(menu.is_active),callback:function ($$v) {_vm.$set(menu, "is_active", $$v)},expression:"menu.is_active"}},[(menu.icon)?_c(VListItemIcon,{staticClass:"marginKanan"},[_c(VAvatar,{attrs:{"color":"blue-darken-2","size":"20","rounded":"0"}},[_c(VImg,{attrs:{"alt":"John","src":menu.icon}})],1)],1):_vm._e(),_c(VListItemContent,[_c(VListItemTitle,[_vm._v(_vm._s(menu.menu_name))])],1)],1):_c(VListGroup,{key:'group' + menu.menu_id,staticClass:"mt-2 mb-2",attrs:{"nav":"","no-action":"","color":"menuWhite"},scopedSlots:_vm._u([{key:"activator",fn:function(){return [(menu.icon)?_c(VListItemIcon,{staticClass:"marginKanan"},[_c(VAvatar,{attrs:{"color":"blue-darken-2","size":"20","rounded":"0"}},[_c(VImg,{attrs:{"alt":"John","src":menu.icon}})],1)],1):_vm._e(),_c(VListItemContent,[_c(VListItemTitle,[_vm._v(_vm._s(menu.menu_name))])],1)]},proxy:true}],null,true),model:{value:(menu.is_active),callback:function ($$v) {_vm.$set(menu, "is_active", $$v)},expression:"menu.is_active"}},[_vm._l((menu.children),function(submenu){return [_c(VListItem,{key:submenu.menu_id + '-' + i,staticClass:"sub-menu",attrs:{"to":submenu.route,"link":""},model:{value:(submenu.is_active),callback:function ($$v) {_vm.$set(submenu, "is_active", $$v)},expression:"submenu.is_active"}},[_c(VListItemContent,[_c(VListItemTitle,[_vm._v(_vm._s(submenu.menu_name))])],1)],1)]})],2)]})],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }