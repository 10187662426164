import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemAvatar } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VSwitch } from 'vuetify/lib/components/VSwitch';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text-center"},[_c(VMenu,{attrs:{"close-on-content-click":false,"nudge-width":200,"offset-x":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c(VBtn,_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c(VIcon,[_vm._v("mdi-account")])],1)]}}]),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c(VCard,[_c(VList,[_c(VListItem,[_c(VListItemAvatar,{attrs:{"color":"blue"}},[_c('img',{attrs:{"src":_vm.user.avatar,"alt":"John"}})]),_c(VListItemContent,[_c(VListItemTitle,[_vm._v(_vm._s(_vm.user.name))]),_c(VListItemSubtitle,[_vm._v(_vm._s(_vm.user.name))])],1),_c(VListItemAction,[_c(VBtn,{class:_vm.fav ? 'red--text' : '',attrs:{"icon":""},on:{"click":function($event){_vm.fav = !_vm.fav}}},[_c(VIcon,[_vm._v("mdi-heart")])],1)],1)],1)],1),_c(VDivider),_c(VList,[_c(VListItem,[_c(VListItemAction,[_c(VSwitch,{attrs:{"color":"purple"},model:{value:(_vm.message),callback:function ($$v) {_vm.message=$$v},expression:"message"}})],1),_c(VListItemTitle,[_vm._v("Enable messages")])],1),_c(VListItem,[_c(VListItemAction,[_c(VSwitch,{attrs:{"color":"purple"},model:{value:(_vm.hints),callback:function ($$v) {_vm.hints=$$v},expression:"hints"}})],1),_c(VListItemTitle,[_vm._v("Enable hints")])],1)],1),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"color":"primary","text":""},on:{"click":_vm.logout}},[_vm._v(" Logout ")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }