<template>
  <v-app id="inspire">
    <v-navigation-drawer v-model="drawer" app class="bgSidebar">
      <v-sheet
        color="transparent"
        class="pt-4 pb-4 justify-center text-center"
        justify="center"
        fixed
      >
        <!-- <h2 dark class="text-white">SMBC</h2> -->
        <!-- <v-row justify="center"> -->
        <v-img
          class="ml-auto mr-auto"
          flat
          src="@/assets/logo_name.png"
          width="100px"
        ></v-img>
        <!-- </v-row> -->
      </v-sheet>
      <v-divider></v-divider>
      <Sidebar />
    </v-navigation-drawer>

    <v-app-bar app>
      <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>
      <v-toolbar-title>{{ title }}</v-toolbar-title>
      <v-spacer></v-spacer>
      <Bell></Bell>
      <v-btn @click="lightbulb()" icon
        ><v-icon v-if="this.$vuetify.theme.dark">mdi-star-crescent</v-icon
        ><v-icon v-else>mdi-weather-sunny</v-icon></v-btn
      >
      <Account v-if="user" />
    </v-app-bar>
    <v-main>
      <router-view />
    </v-main>
    <Loader />
  </v-app>
</template>

<script>
import Sidebar from "@/components/navigation/Sidebar.vue";
import { mapState } from "vuex";
import Loader from "@/components/Loading/loading.vue";
import Bell from "@/components/notifications/Bell";
import Account from "@/components/Modal/account.vue";
export default {
  components: { Sidebar, Loader, Bell, Account },
  name: "AdminLayout",
  props: {
    title: {
      type: String,
      required: false,
      default: "SISTEM INFORMASI SMBC",
    },
  },
  data: () => ({
    cards: ["Today", "Yesterday"],
    drawer: null,
    items: [
      { title: "Profil", url: "/Profile" },
      { title: "Logout", url: "/logout" },
    ],
  }),
  computed: {
    ...mapState("auth", {
      user: (state) => state.user,
    }),
  },
  mounted() {
    const theme = localStorage.getItem("dark_theme");
    if (theme) {
      if (theme === "true") {
        this.$vuetify.theme.dark = true;
      } else {
        this.$vuetify.theme.dark = false;
      }
    } else if (
      window.matchMedia &&
      window.matchMedia("(prefers-color-scheme: dark)").matches
    ) {
      this.$vuetify.theme.dark = true;
      localStorage.setItem("dark_theme", this.$vuetify.theme.dark.toString());
    }
  },
  methods: {
    lightbulb() {
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
      localStorage.setItem("dark_theme", this.$vuetify.theme.dark.toString());
      // this.$store.dispatch("home/setImageText");
    },
  },
};
</script>
<style>
.theme--light .v-main__wrap {
  background-color: #f0f0f1;
}
.theme--light th {
  font-size: 16px !important;
  background-color: #edf2f7;
}
</style>
