import AuthenticationService from "@/services/AuthenticationService";
// import store from "@/store";
// import alerts from "@/components/alerts/alert";
// import router from "@/router";

const aspect = {
  namespaced: true,
  state: {
    datas: {},
    queryParam: {},
  },
  getters: {
    getDatas(state) {
      return state.datas;
    },
  },
  mutations: {
    SET_DATAS(state, datas) {
      state.datas = datas;
    },
    SET_LOADING(state, loading) {
      state.loading = loading;
    },
    SET_QUERY_PARAM(state, data) {
      state.queryParam = data;
    },
  },
  actions: {
    async paginate({ commit }, payload) {
      commit("SET_LOADING", true);
      commit("SET_QUERY_PARAM", payload);
      return await AuthenticationService.get("aspect/page?" + payload).then(
        ({ data }) => {
          commit("SET_DATAS", data.data);
          commit("SET_LOADING", false);
        }
      );
    },
    setIsAactiveFalse({ commit, state }) {
      const menuNew = state.sidebar;
      menuNew.forEach((element, i) => {
        if (element.slug === "dropdown") {
          menuNew[i].isActive = false;
        }
      });
      commit("SET_SIDEBAR", menuNew);
    },
  },
};

export default aspect;
