<template>
  <v-app id="inspire" :style="cardStyle">
    <v-main>
      <router-view></router-view>
    </v-main>
  </v-app>
</template>

<script>
import store from "@/store";
import router from "@/router";
export default {
  name: "LayoutBlankPage",
  components: {},
  data() {
    return {
      backgroundImage: require("@/assets/bg2.png"),
    };
  },
  mounted() {
    if (store.getters["auth/isAuthenticated"]) {
      router.push({ name: "DashboardPage" });
    }
  },
  computed: {
    cardStyle() {
      return {
        backgroundImage: `url(${this.backgroundImage})`,
        backgroundSize: "cover !important",
        height: "100vh !important;",
      };
    },
  },
};
</script>
