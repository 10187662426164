import Vue from "vue";
import VueRouter from "vue-router";
// import store from "@/store";
// layouts
import Blanks from "@/layouts/BlankPage.vue";
import Cms from "@/layouts/CmsPage.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Index",
    redirect: () => {
      return "login";
    },
  },
  {
    path: "*",
    name: "*",
    component: () => import("@/views/Notfound.vue"),
  },
  {
    path: "/",
    component: Blanks,
    meta: { requiresAuth: false },
    children: [
      {
        path: "login",
        name: "Login",
        component: () => import("@/views/pages/Auth/Login.vue"),
      },
      {
        path: "Forgot-password",
        name: "ForgotPassword",
        component: () => import("@/views/pages/Auth/ForgotPassword.vue"),
      },
    ],
  },
  {
    path: "/",
    component: Cms,
    meta: { requiresAuth: true },
    children: [
      {
        path: "dashboard",
        name: "DashboardPage",
        component: () => import("@/views/pages/Cms/HomeView.vue"),
      },
      {
        path: "sispena",
        name: "SispenaPage",
        component: () => import("@/views/pages/Cms/sispena/paginate.vue"),
      },
      {
        path: "finance",
        name: "FinancePage",
        component: () => import("@/views/pages/Cms/keuangan/paginate.vue"),
      },
      {
        path: "teacher",
        name: "TeacherPage",
        component: () => import("@/views/pages/Cms/guru/paginate.vue"),
      },
      {
        path: "student",
        name: "StudentPage",
        component: () => import("@/views/pages/Cms/siswa/paginate.vue"),
      },
      {
        path: "staff",
        name: "StaffPage",
        component: () => import("@/views/pages/Cms/staff/paginate.vue"),
      },
      {
        path: "task",
        name: "TaskPage",
        component: () => import("@/views/pages/Cms/tugas/paginate.vue"),
      },
      {
        path: "room",
        name: "RoomPage",
        component: () => import("@/views/pages/Cms/ruangan/paginate.vue"),
      },
      {
        path: "absence",
        name: "AbsencePage",
        component: () => import("@/views/pages/Cms/absensi/paginate.vue"),
      },
    ],
  },
  {
    path: "/master-system",
    component: Cms,
    meta: { requiresAuth: true },
    children: [
      {
        path: "access-level",
        name: "MasterSystemAccessLevelPage",
        component: () =>
          import("@/views/pages/Cms/master_system/roles/paginate.vue"),
      },
      {
        path: "menu",
        name: "MasterSystemMenuPage",
        component: () =>
          import("@/views/pages/Cms/master_system/menu/paginate.vue"),
      },
      {
        path: "rules",
        name: "MasterSystemRulesPage",
        component: () =>
          import("@/views/pages/Cms/master_system/rules/paginate.vue"),
      },
      {
        path: "access-menu",
        name: "MasterSystemAccessMenuPage",
        component: () =>
          import("@/views/pages/Cms/master_system/asccess_menus/paginate.vue"),
      },
    ],
  },
  {
    path: "/backoffice",
    component: Cms,
    meta: { requiresAuth: true },
    children: [
      {
        path: "users",
        name: "BackofficeUsersPage",
        component: () =>
          import("@/views/pages/Cms/backoffice/users/paginate.vue"),
      },
      {
        path: "classes",
        name: "BackofficeClassesPage",
        component: () =>
          import("@/views/pages/Cms/backoffice/classes/paginate.vue"),
      },
      {
        path: "personality",
        name: "BackofficePersonalityPage",
        component: () =>
          import("@/views/pages/Cms/backoffice/personality/paginate.vue"),
      },
      {
        path: "scores",
        name: "BackofficeScoresPage",
        component: () =>
          import("@/views/pages/Cms/backoffice/scores/paginate.vue"),
      },
      {
        path: "aspect",
        name: "BackofficeAspectPage",
        component: () =>
          import("@/views/pages/Cms/backoffice/aspect/paginate.vue"),
      },
      {
        path: "fee-type",
        name: "BackofficeFeeTypePage",
        component: () =>
          import("@/views/pages/Cms/backoffice/fee_type/paginate.vue"),
      },
      {
        path: "expense-type",
        name: "BackofficeExpenseTypePage",
        component: () =>
          import("@/views/pages/Cms/backoffice/expense_type/paginate.vue"),
      },
    ],
  },
  {
    path: "/finance",
    component: Cms,
    meta: { requiresAuth: true },
    children: [
      {
        path: "fee",
        name: "FinanceFeePage",
        component: () => import("@/views/pages/Cms/finance/fee/paginate.vue"),
      },
      {
        path: "payment",
        name: "PaymentFeePage",
        component: () =>
          import("@/views/pages/Cms/finance/payment/paginate.vue"),
      },
      {
        path: "expense",
        name: "PaymentExpensePage",
        component: () =>
          import("@/views/pages/Cms/finance/expense/paginate.vue"),
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

// router.beforeEach((to, from, next) => {
//   if (to.matched.some((record) => record.meta.requiresAuth)) {
//     next();
//   } else {
//     if (store.getters["auth/isAuthenticated"]) {
//       next("/dashboard");
//     } else {
//       store.dispatch("auth/LogOut");
//     }
//   }
// });

export default router;
