import { VApp } from 'vuetify/lib/components/VApp';
import { VAppBar } from 'vuetify/lib/components/VAppBar';
import { VAppBarNavIcon } from 'vuetify/lib/components/VAppBar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VMain } from 'vuetify/lib/components/VMain';
import { VNavigationDrawer } from 'vuetify/lib/components/VNavigationDrawer';
import { VSheet } from 'vuetify/lib/components/VSheet';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VApp,{attrs:{"id":"inspire"}},[_c(VNavigationDrawer,{staticClass:"bgSidebar",attrs:{"app":""},model:{value:(_vm.drawer),callback:function ($$v) {_vm.drawer=$$v},expression:"drawer"}},[_c(VSheet,{staticClass:"pt-4 pb-4 justify-center text-center",attrs:{"color":"transparent","justify":"center","fixed":""}},[_c(VImg,{staticClass:"ml-auto mr-auto",attrs:{"flat":"","src":require("@/assets/logo_name.png"),"width":"100px"}})],1),_c(VDivider),_c('Sidebar')],1),_c(VAppBar,{attrs:{"app":""}},[_c(VAppBarNavIcon,{on:{"click":function($event){_vm.drawer = !_vm.drawer}}}),_c(VToolbarTitle,[_vm._v(_vm._s(_vm.title))]),_c(VSpacer),_c('Bell'),_c(VBtn,{attrs:{"icon":""},on:{"click":function($event){return _vm.lightbulb()}}},[(this.$vuetify.theme.dark)?_c(VIcon,[_vm._v("mdi-star-crescent")]):_c(VIcon,[_vm._v("mdi-weather-sunny")])],1),(_vm.user)?_c('Account'):_vm._e()],1),_c(VMain,[_c('router-view')],1),_c('Loader')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }