<template>
  <v-card width="100%" class="mx-auto" tile color="transparent">
    <v-list dark dense rounded color="transparent">
      <template v-for="(menu, i) in sidebar">
        <v-list-item
          v-model="menu.is_active"
          :key="'item' + menu.menu_id"
          v-if="menu.menu_type === 'LINK'"
          :to="menu.route"
          class="mt-2 mb-2"
          @click="toggleSubmenu()"
        >
          <v-list-item-icon class="marginKanan" v-if="menu.icon">
            <v-avatar color="blue-darken-2" size="20" rounded="0">
              <v-img alt="John" :src="menu.icon"></v-img>
            </v-avatar>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ menu.menu_name }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-group
          nav
          no-action
          class="mt-2 mb-2"
          color="menuWhite"
          :key="'group' + menu.menu_id"
          v-else
          v-model="menu.is_active"
        >
          <template v-slot:activator>
            <v-list-item-icon class="marginKanan" v-if="menu.icon">
              <v-avatar color="blue-darken-2" size="20" rounded="0">
                <v-img alt="John" :src="menu.icon"></v-img>
              </v-avatar>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ menu.menu_name }}</v-list-item-title>
            </v-list-item-content>
          </template>

          <template v-for="submenu in menu.children">
            <v-list-item
              :key="submenu.menu_id + '-' + i"
              v-model="submenu.is_active"
              :to="submenu.route"
              link
              class="sub-menu"
            >
              <v-list-item-content>
                <v-list-item-title>{{ submenu.menu_name }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
        </v-list-group>
      </template>
      <!-- <div v-for="item in sidebar" :key="item.id">
        <v-list-item v-if="item.slug === 'link'" v-model="item.isActive" link
          :to="item.href" @click="toggleSubmenu()">
            <v-list-item-icon>
              <v-icon>mdi-{{ item.mdiIcon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-title v-text="item.name"></v-list-item-title>
        </v-list-item>
        <v-list-group
        v-else-if="item.slug === 'dropdown'"
        :prepend-icon="`mdi-${item.mdiIcon}`"
        no-action
        dark
        v-model="item.isActive"
        >
          <template v-slot:activator>
              <v-list-item-title v-text="item.name"></v-list-item-title>
          </template>
          <v-list-item v-for="ch in item.elements" :key="ch.id" link :to="ch.href">
            <v-list-item-content>
              <v-list-item-title v-text="ch.name"></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>
        </div> -->
    </v-list>
  </v-card>
</template>
<script>
import { mapState } from "vuex";
import store from "@/store";
export default {
  name: "SidebarNav",
  data: () => ({
    items: [],
    submenuOpen: false,
  }),
  mounted: function () {
    store.dispatch("sidebar/reqSidebar");
  },
  computed: {
    ...mapState("sidebar", {
      sidebar: (state) => state.sidebar,
    }),
  },
  methods: {
    toggleSubmenu() {
      this.sidebar.forEach((element) => {
        if (element.menu_type === "DROPDOWN") {
          element.is_active = false;
        }
      });
    },
  },
};
</script>
<style>
/* .mb-3{
  margin-bottom: 3px !important;
}

.mt-3{
  margin-top: 3px !important;
} */
/* .label-menu{
  color: white !important;
  font-size: 13px !important;
}
.v-icon.notranslate.mdi-chevron-down{
  color: white !important;
  font-size: 13px !important;
} */
/* .v-list-item__title{
  white-space:normal !important
} */

/* .v-list-item.v-list-item--active.v-list-item--link {
  background-color: #ffffff !important;
  color: #845EC2;
} */

.list--dense.fix .list__tile__title {
  height: 20px;
}

.list__tile__title {
  /* background: #efefef; */
}

.closer .list__tile .avatar,
.closer .list__tile__action {
  min-width: 24px;
}
.marginKanan {
  margin-right: 10px !important;
}
.v-list-item.v-list-item--link.sub-menu {
  padding-left: 50px !important;
}
</style>
