import alerts from "@/components/alerts/alert";
const confirmations = {
  confirmationSave(
    module,
    payload = [],
    title = "Are you sure?",
    text = "",
    button = ""
  ) {
    alerts.questionConfirmation(payload, module, title, text, button);
  },
  confirmationUpdate(
    module,
    payload = [],
    title = "Are you sure?",
    text = "Setelah submit data tidak dapat dikembalikan",
    button = ""
  ) {
    alerts.questionConfirmation(payload, module, title, text, button);
  },
  confirmationDelete(
    module,
    payload = [],
    title = "Are you sure?",
    text = "You won't be able to revert this!",
    button = ", delete it!",
    icon = "warning"
  ) {
    alerts.questionConfirmation(payload, module, title, text, button, icon);
  },
  confirmationApprove(
    module,
    payload = [],
    title = "Are you sure?",
    text = "Setelah submit data tidak dapat dikembalikan.",
    button = ""
  ) {
    alerts.questionConfirmation(payload, module, title, text, button);
  },
  confirmationReject(
    module,
    payload = [],
    title = "Apakah yakin ingin direject ?",
    text = "Setelah Reject data tidak dapat dikembalikan.",
    button = ", Reject it"
  ) {
    alerts.questionConfirmation(payload, module, title, text, button);
  },
  confirmationForm(
    module,
    payload = [],
    title = "Are you sure?",
    text = "",
    button = ""
  ) {
    alerts.questionConfirmationForm(payload, module, title, text, button);
  },
};

export default confirmations;
