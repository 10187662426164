import AuthenticationService from "./../../services/AuthenticationService";
import alerts from "@/components/alerts/alert";
import Store from "@/store";
import confirmations from "./confirmations";

const users = {
  namespaced: true,
  state: {
    datas: {},
    loading: false,
    label: "Create",
    modal: false,
    form: {},
    queryParam: null,
  },
  getters: {
    getData(state) {
      return state.datas;
    },
  },
  mutations: {
    SET_DATAS(state, data) {
      state.datas = data;
    },
    SET_LOADING(state, loading) {
      state.loading = loading;
    },
    SET_QUERY_PARAM(state, data) {
      state.queryParam = data;
    },
    SET_FORM(state, data) {
      state.form = data;
    },
    SET_LABEL(state, data) {
      state.label = data;
    },
  },
  actions: {
    async paginate({ commit }, payload) {
      commit("SET_LOADING", true);
      commit("SET_QUERY_PARAM", payload);
      return await AuthenticationService.get("users/page?" + payload).then(
        ({ data }) => {
          commit("SET_DATAS", data.data);
          commit("SET_LOADING", false);
        }
      );
    },
    onRefresh({ state }) {
      Store.dispatch("users/page", {
        loading: true,
        datas: state.queryParam,
      });
    },
    // async paginate ({ commit }, payload) {
    //   commit('SET_LOADING', true)
    //   return await AuthenticationService.get('users/paginate?' + payload).then(({ data }) => {
    //     commit('SET_DATAS', data.data)
    //     commit('SET_LOADING', false)
    //   })
    // },
    async reqDataUsers({ commit }, payload) {
      commit("SET_LOADING", payload);
      return await AuthenticationService.get("users").then(({ data }) => {
        commit("SET_DATAS", data.data);
        commit("SET_LOADING", false);
      });
    },
    async setActive({ commit }, payload) {
      const params = {
        id: payload[1],
        active: payload[0],
      };
      return await AuthenticationService.put(
        "users/set-active/" + payload[1],
        params
      ).then(({ data }) => {
        commit("SET_LOADING", false);
        console.log(JSON.stringify(data));
        alerts.sweetalertToast(
          data.code === 200 ? "success" : "error",
          data.message
        );
      });
    },
    confirmationSave() {
      confirmations.confirmationForm("users/save");
    },
    async save({ state }) {
      Store.dispatch("loading/setLoading", true);
      try {
        if (state.label === "Create") {
          return await AuthenticationService.post(
            "users/create",
            state.form
          ).then(({ data }) => {
            Store.dispatch("loading/setLoading", false);
            state.datas.push(state.form);
            if (data.code === 200) {
              Store.dispatch("users/all", false);
              state.modal = false;
            }
            alerts.sweetalertToast(
              data.code === 200 ? "success" : "error",
              data.message
            );
          });
        } else if (state.label === "Update") {
          return await AuthenticationService.post(
            "users/update/" + state.form.id,
            state.form
          ).then(({ data }) => {
            Store.dispatch("loading/setLoading", false);
            if (data.code === 200) {
              Store.dispatch("users/all", false);
              state.modal = false;
            }
            alerts.sweetalertToast(
              data.code === 200 ? "success" : "error",
              data.message
            );
          });
        } else if (state.label === "Delete") {
          return await AuthenticationService.delete(
            "users/delete/" + state.form.id
          ).then(({ data }) => {
            Store.dispatch("loading/setLoading", false);
            if (data.code === 200) {
              const index = state.datas.findIndex(
                (item) => item.id === state.form.id
              );
              state.datas.splice(index, 1);
              Store.dispatch("users/all", false);
            }
            alerts.sweetalertToast(
              data.code === 200 ? "success" : "error",
              data.message
            );
          });
        }
      } catch (error) {
        Store.dispatch("loading/setLoading", false);
        alerts.sweetalertToast("error", error.message);
      }
    },
    onAction({ commit, state }, payload) {
      commit("SET_LABEL", payload[1]);
      commit("SET_FORM", payload[0]);
      if (payload[1] === "Delete") {
        confirmations.confirmationDelete("users/save");
      } else {
        state.modal = true;
      }
    },
    onInputImage({ state }, payload) {
      state.form[payload[1]] = payload[0];
    },
  },
};

export default users;
