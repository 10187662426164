import AuthenticationService from "@/services/AuthenticationService";
import alerts from "@/components/alerts/alert";
import store from "@/store";
import router from "@/router";

const auth = {
  namespaced: true,
  state: {
    msgError: [],
    loading: false,
    captcha: {},
    token: null,
    user: null,
    form: {
      username: "",
      password: "",
      captcha: "",
      config: "flat",
      key: "",
      remember_me: true,
      coordinate: "",
      fcm_token: "",
      recaptcha_response:
        "03AFcWeA4vEjC1VfXhxrRBPZLxiB6SW_XL3OGjDiRFCtBJ97yzPlEH27unxl_dVI6WF2dPrMfXy7vBKkb8-DYT4rvmkJdnWm1ApZlQsCBm_6Jm5oVzI66YAGaCpJbvdvGeTAczodYIxkLNgTP3CrtiLoR00fDZ4D2qD2g5NBMxL1HD7DP9SOT0BADnJ5LTkgEyNgodLb4xfkimWnDHfs81sOJvhbHnYo6g7_tF39FXW1mT2nFmxRymqIyuY0URDtK_C1gNtbkoHAcgj8RDFFj-pAQ07SKDgzodWITKh1PmpudfU7vR3ksQ58s-Uhvxgwz8a9gUsD7j9EychxnqAYek5-tFN077tMSyo5Unen_qv7LGUbkFo_VDBRXV6tONUuG2z1nqk8nn7y66xVCxdHqSdqovG-gWVqvFBp8Y_dDJGB7uoYE6JYo8S28_CzrhX8jv98LrcfmK7fmYZQlCpTVTQDEmGvGfH3OFfJ19-aQ-X1o7ZztBBtpfi41RO-tVTvg4J9GGWAIzrF2lflcup2J0Std5-NocRGdqseiWDz2ANx9GaEXUzQINHc0tkq77-DhJmCT5tJtM_TmOxxk3DM6xJ97Y9OZvMpQbyHfF6r-NqD2XIrbUOzXSA4w",
    },
  },
  getters: {
    getCaptcha(state) {
      return state.captcha;
    },
    isAuthenticated: (state) => !!state.token,
    token: (state) => state.token,
    user: (state) => state.user,
  },
  mutations: {
    SET_LOADING(state, loading) {
      state.loading = loading;
    },
    SET_CAPTCHA(state, captcha) {
      state.captcha = captcha;
    },
    SET_TOKEN(state, token) {
      state.token = token;
    },
    SET_USER(state, user) {
      state.user = user;
    },
    CLEAR_TOKEN_LS(state, token) {
      state.token = token;
      localStorage.removeItem("auth-persist-state");
      localStorage.removeItem("key-slide-persist-state");
      localStorage.removeItem("profile-persist-state");
      localStorage.removeItem("token-persist-state");
      localStorage.removeItem("sidebar-persist-state");
      router.push({ name: "Login" });
    },
  },
  actions: {
    async forgotPassword({ commit }, payload) {
      commit("SET_LOADING", true);
      return AuthenticationService.post("reset-password", payload)
        .then(({ data }) => {
          console.log(data);
          commit("SET_LOADING", false);
        })
        .catch((error) => {
          alerts.sweetalertToast("error", error.response.data.message);
          commit("SET_LOADING", false);
        });
    },
    async reqCaptcha({ commit }) {
      return await AuthenticationService.get("get-captcha/flat").then(
        ({ data }) => {
          commit("SET_CAPTCHA", data);
        }
      );
    },
    async refreshToken({ commit }, payload = []) {
      commit("SET_LOADING", true);
      return await AuthenticationService.post("cms/refresh", payload)
        .then(({ data }) => {
          commit("SET_LOADING", false);
          commit("SET_TOKEN", data.data.access_token);
          commit("SET_USER", data.data.user);
        })
        .catch((error) => {
          const token = null;
          commit("SET_LOADING", false);
          commit("CLEAR_TOKEN_LS", token);
          alerts.sweetalertToast("error", error.response.data.error);
        });
    },
    async login({ commit, state }) {
      commit("SET_LOADING", true);
      state.form.key = state.captcha.key;
      state.form.captcha = state.form.captcha.toLowerCase();
      return await AuthenticationService.post("auth/login", state.form)
        .then(({ data }) => {
          commit("SET_LOADING", false);
          commit("SET_TOKEN", data.data.access_token);
          commit("SET_USER", data.data.user);
          state.form.captcha = "";
          router.push({ name: "DashboardPage" });
        })
        .catch((error) => {
          store.dispatch("auth/reqCaptcha");
          commit("SET_LOADING", false);
          alerts.sweetalertToast("error", error.response.data.message);
          state.form.captcha = "";
        });
    },
    async LogOut({ commit }) {
      const token = null;
      commit("CLEAR_TOKEN_LS", token);
    },
  },
};

export default auth;
